import React, { Component} from "react";
import ReactDOM from 'react-dom';
import ChartistGraph from 'react-chartist';
import "./workareas.style.css";

const prettyCategory = {
  cleanup: 'Curacao Cleanup',
  socialdistance: 'Social Distancing',
  businessopen: 'Unauthorized Business Open',
  curfew: 'Curfew Violation',
  pothole: 'Pothole',
  access: 'Accessibility issue',
  kumpra_konsiente: 'Kumpra Konsiente',
  accident: 'Accident',
  garbage: 'Garbage in the street',
  dumping: 'Illegal Dumping',
  dead_animal: 'Dead Animal',
  bulky: 'Bulky Waste',
  graffiti: 'Graffiti',
  vandalism: 'Vandalism',
  stray_animal: 'Stray animal',
  power_line_water: 'Broken Power Line / Water Main',
  food_safety: 'Food Safety',
  price_difference: 'Price difference',
  other: 'Other'
}

class Graphs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      which: this.props.which,
      allData: this.props.allData,
      theData: {},
      options: {},
      type: '',
    };
    
    this.makeReportsWeekly = this.makeReportsWeekly.bind(this);
    //this.afterOpenModal = this.afterOpenModal.bind(this);
    //this.closeModal = this.closeModal.bind(this);
  }
  
  componentDidMount(){
    if(this.state.which==='reportsWeekly') this.makeReportsWeekly();
  }
  
  makeReportsWeekly(){
    
    const orderByDate =  [].concat(this.state.allData)
      .sort(function(a,b){
        if(a['submitted'] > b['submitted']) { return -1; }
        if(a['submitted'] < b['submitted']) { return 1; }
        return 0;
      })
    //console.log(orderByDate);
    
    let brackets = {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: [],
      8: [],
    };
    const d = new Date();
    const now = d.getTime();
    const oneWeek = 1000*60*60*24*7; //amount of miliseconds in a week
    
    for(var i=0;i<orderByDate.length;i++){
      //console.log(orderByDate[i]['reportID']);
      if(orderByDate[i]['submitted'] > now-oneWeek) brackets[1].push(orderByDate[i]);
      else if (orderByDate[i]['submitted'] > now-(oneWeek*2)) brackets[2].push(orderByDate[i]);
      else if (orderByDate[i]['submitted'] > now-(oneWeek*3)) brackets[3].push(orderByDate[i]);
      else if (orderByDate[i]['submitted'] > now-(oneWeek*4)) brackets[4].push(orderByDate[i]);
      else if (orderByDate[i]['submitted'] > now-(oneWeek*5)) brackets[5].push(orderByDate[i]);
      else if (orderByDate[i]['submitted'] > now-(oneWeek*6)) brackets[6].push(orderByDate[i]);
      else if (orderByDate[i]['submitted'] > now-(oneWeek*7)) brackets[7].push(orderByDate[i]);
      else if (orderByDate[i]['submitted'] > now-(oneWeek*8)) brackets[8].push(orderByDate[i]);
    }
    
    var data = {
      labels: [-8,-7,-6,-5,-4,-3,-2,-1],
      series: [
        [
          brackets[8].length,
          brackets[7].length,
          brackets[6].length,
          brackets[5].length,
          brackets[4].length,
          brackets[3].length,
          brackets[2].length,
          brackets[1].length
        ]
      ]
    };
    /* axis options 
      showLabel: false,
      showGrid: false
    */
    var options = {
      low: 0,
      axisX: {
        labelInterpolationFnc: function(value, index) {
          return index % 2 !== 0 ? value : null;
        }
      },
      
    };
    this.setState({
      theData:data,
      options: options,
      type: 'Line',
    });
  }
  
  
  render(){
 
    return(
      <div>
      {this.state.type!=='' ? (
        <ChartistGraph className={'ct-chart'} data={this.state.theData} options={this.state.options} type={this.state.type} />
      ) : ('')}
      </div>
    )
  }
  
}

export default Graphs;