import React from "react";
import { Link } from 'react-router-dom';
import isAuthenticated from '../../auth/isauthenticated';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import "./navbar.style.css";

const NavBar = () => {
  //const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <div id="navbar-right" className="navbar-main">
      <ul>
        <li className="level-1">
          <OverlayTrigger
            key="logout"
            placement="bottom"
            overlay={
              <Tooltip id="tooltip-logout">
                Logout
              </Tooltip>
            }
          >
            <Link to='/logout'>
              <img height={20} src="/Logout.png" alt="Reports" />
            </Link>
          </OverlayTrigger>
        </li>
      </ul>
    </div>
  );
};

export default NavBar;