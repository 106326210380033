import React, { Component} from "react";
import ReactDOM from 'react-dom'
import Modal from 'react-modal';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import { withRouter, BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./leaflet.style.css";

var prettyCategory = {
  cleanup: 'Curacao Cleanup',
  socialdistance: 'Social Distancing',
  businessopen: 'Unauthorized Business Open',
  curfew: 'Curfew Violation',
  pothole: 'Pothole',
  access: 'Accessibility issue',
  accident: 'Accident',
  garbage: 'Garbage in the street',
  dumping: 'Illegal Dumping',
  dead_animal: 'Dead animal',
  graffiti: 'Graffiti',
  vandalism: 'Vandalism',
  stray_animal: 'Stray animal',
  power_line_water: 'Broken Power Line / Water Main',
  food_safety: 'Food Safety',
  socialdistance: 'Social Distancing',
  businessopen: 'Unauthorized Business Open',
  curfew: 'Curfew violation',
  other: 'Other'
}

var prettySolved = {
  cleanup: <div className="popupsolvedtext">Handled</div>,
  socialdistance: <div className="popupsolvedtext">Handled</div>,
  businessopen: <div className="popupsolvedtext">Handled</div>,
  curfew: <div className="popupsolvedtext">Handled</div>,
  pothole: <div className="popupsolvedtext">Fixed by</div>,
  access: <div className="popupsolvedtext">Fixed</div>,
  accident: <div className="popupsolvedtext">Resolved</div>,
  garbage: <div className="popupsolvedtext">Cleaned</div>,
  dumping: <div className="popupsolvedtext">Cleaned</div>,
  dead_animal: <div className="popupsolvedtext">Removed</div>,
  graffiti: <div className="popupsolvedtext">Cleaned</div>,
  vandalism: <div className="popupsolvedtext">Handled</div>,
  stray_animal: <div className="popupsolvedtext">Handled</div>,
  power_line_water: <div className="popupsolvedtext">Fixed</div>,
  food_safety: <div className="popupsolvedtext">Handled</div>,
  socialdistance: <div className="popupsolvedtext">Responded To</div>,
  businessopen: <div className="popupsolvedtext">Responded To</div>,
  curfew: <div className="popupsolvedtext">Responded To</div>,
  other: <div className="popupsolvedtext">Solved</div>
}
var prettyLogos = {
  'Smart Mobility': '',
  'self': '',
  'Selikor': <div style={{display: 'table'}}><div className="popupsolvedby"> by </div><div className="popupsolvedimg"><a href="https://www.selikor.com/" target="_blank"><img src="/selikor.png" alt="selikor" /></a></div></div>,
  'MEO': <div style={{display: 'table'}}><div className="popupsolvedby"> by </div><div className="popupsolvedimg"><a href="https://gobiernu.cw/ministerionan/desaroyo-ekonomiko/" target="_blank"><img src="/solved-meo.png" alt="MEO" /></a></div></div>,
  'VVRP': <div style={{display: 'table'}}><div className="popupsolvedby"> by </div><div className="popupsolvedimg"><a href="https://gobiernu.cw/ministerionan/trafiko-transporte-i-planifikashon-urbano/" target="_blank"><img src="/solved-vvrp.png" alt="VVRP" /></a></div></div>,
}

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
  }
};

Modal.setAppElement('#root');

class MyMarker extends Component {
  constructor(props) {
    super(props);
    
    var imgVariations = {
      small: '',
      medium: '',
      large: '',
    }
    
    this.state = {
      fullItem: this.props.fullItem,
      content: null,
      modalIsOpen: false,
      imgURL: imgVariations,
      reportType: '',
      theirName: '',
      submitted: null,
      comments: '',
      solved: null,
    };
    
      //comments: this.props.fullItem.comments
    this._handleClick = this._handleClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this._checkUrlForOpen = this._checkUrlForOpen.bind(this);
    this._nl2br = this._nl2br.bind(this);
  }
  
  componentWillMount() {
    
  }
  
  componentDidMount() {
    if(this.props.fullItem!==undefined) {
      let imgName = this.props.fullItem.imgName;
      let imgVariations = {};
      imgName = (imgName!=='' && imgName.indexOf('.jpg')===-1) ? imgName + '.jpg' : (imgName!=='') ? imgName : '';
      if(imgName!='') {
        imgName = 'https://api.curacao311.com/uploads/' + imgName;
        imgVariations = {
          small: imgName.replace('.jpg','-ss.jpg'),
          medium: imgName.replace('.jpg','-s.jpg'),
          large: imgName,
        }
      }
      this.setState({
        comments: this.props.fullItem.comments,
        imgURL: imgVariations,
        reportType: this.props.fullItem.reportType,
        theirName: this.props.fullItem.theirName,
        submitted: this.props.fullItem.submitted,
        solved: this.props.fullItem.solved,
      }, function(){ this._checkUrlForOpen() });
    }
  }
  
  _checkUrlForOpen() {
    if(this.props.location.pathname==='/report/'+this.props.markerId) {
      //this.props.map.leafletElement.flyTo(this.props.position,15);
      this.props.map.leafletElement.setView(this.props.position,18,{'animate':false});
      this.setState({
        content: this._getContent(),
      }, function(){ 
        this.refs.theMarker.leafletElement.openPopup(); 
      });
    }
  }
  
  openModal() {
    this.setState({modalIsOpen: true});
  }
  
  afterOpenModal() {
    // references are now sync'd and can be accessed.
    //this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }
  
  _nl2br(str){
    let strBr = '';
    str.split('\n').map(function(item, key) {
      strBr += <span key={key}> {item} <br/> </span>;
    });
    return <p>{strBr}</p>;
  }
  
  _getContent() {
    var theirName = (this.state.theirName!=="null") ? <em>By: {this.state.theirName}, </em> : null;
    var comments = (this.state.comments!=="null") ? <p>{this.state.comments}</p> : null;
    var xtraClass = (this.state.fullItem.imgName!=="") ? "popupcontent has-image" : "popupcontent no-image";
    var d = new Date(parseInt(this.state.submitted,10));
    var dYear = d.getFullYear();
    var dMonths = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var dMonth = dMonths[d.getMonth()];
    var dDay = (d.getDate()<10) ? '0'+d.getDate() : d.getDate();
    var dHour = (d.getHours()<10) ? '0'+d.getHours() : d.getHours();
    var dMin = (d.getMinutes()<10) ? '0'+d.getMinutes() : d.getMinutes();
    var submitted = dMonth + ' ' + dDay + ', ' + dYear + ' ' + dHour + ':' + dMin;
    var solvedText = this.state.solved==='yes' ? (<span className="popupsolvedcontent">{prettySolved[this.state.reportType.replace(/-/g,'_')]} {prettyLogos[this.state.fullItem.organization]}</span>) : null;
    var title = this.state.solved==='yes' ? "[SOLVED] " : "";
    title += prettyCategory[this.state.reportType.replace(/-/g,'_')] + " reported on " + submitted;
    var anon = (this.state.theirName!=="null") ? this.state.theirName : "Anonymous";
    var description = "Issue reported with the Cura&ccedil;ao 311 app by " + anon + " on " + submitted + " in the category " + prettyCategory[this.state.reportType.replace(/-/g,'_')];
    var url = "https://www.curacao311.com/report/" + this.props.markerId;
    var content = (
      <div className={xtraClass}>
        <div className="popupimage">
          <img src={this.state.imgURL.small} className="imgName" onClick={this.openModal} />
          <div className="popuptext">
            {solvedText!==null ? (
              <div className="popupsolved">{solvedText}</div>
            ) : (
              null
            )}
            <h3>{prettyCategory[this.state.reportType.replace(/-/g,'_')]}</h3>
              {comments}
            <span className="namedate">{theirName} {submitted}</span>
          </div>
        </div>
      </div>
    );
    return content;
  }
  
  _handleClick() {
    
    //this.props.map.leafletElement.flyTo(this.props.position);
    //this.props.map.leafletElement.panBy([0,-200]);
    this.setState({
      content: this._getContent(),
    });
    //console.log(this.props.map.leafletElement,this.props.position);
    //this.props.map.leafletElement.panTo(this.props.position);
    //this.props.history.push('/report/'+this.props.markerId);
    
  }
  
  render(){
  
    const initMarker = ref => {
      if (ref ) {
        //ref.leafletElement.openPopup();
        if(this.props.location.pathname==='/report/'+this.props.markerId) {
          //ref.leafletElement.openPopup();
          //console.log(this.props.location);
          //ref.leafletElement.openPopup();
          /*
          this.setState({
            content: this._getContent(),
          }, function() { ref.leafletElement.openPopup() });
          */
        }
      }
    }
    
    return(
          <Marker ref="theMarker" position={this.props.position} onClick={()=>this._handleClick()}>
            <Popup>
            {this.state.content}
            </Popup>
        
            <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel={prettyCategory[this.state.fullItem.reportType.replace(/-/g,'_')]}
            >
              <img src={this.state.imgURL.medium} className="imgName-medium" />
            </Modal>
            
          </Marker>
    
    );
  }
  
}
export default withRouter(props => <MyMarker {...props} />);
//export default MyMarker;