import React, { Component} from "react";
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Sidebar from '../sidebar';
import WorkArea from '../workarea/workarea';
import isAuthenticated from '../../auth/isauthenticated';
import { Auth0Context } from "../../react-auth0-wrapper";
import Spinner from '../spinner';
import "./root.style.css";


class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: isAuthenticated(),
      current: "/",
      loading: true,
      user: null,
      loadingUser: true,
      user_extended: null,
    };
    
    //this.openModal = this.openModal.bind(this);
    //this.afterOpenModal = this.afterOpenModal.bind(this);
    //this.closeModal = this.closeModal.bind(this);
  }
  static contextType = Auth0Context;
  
  componentDidMount() {
    const { getTokenSilently, loading, user } = this.context;
    this.setState({loading:loading});
    
  }
  
  async getUser(){
    const { getTokenSilently } = this.context;

    try {
      const token = await getTokenSilently();
      //console.log(token);
      this.setState({loadingRows:true});
      const response = await fetch("https://api2.curacao311.com/api/private/get_userinfo", {
        headers: new Headers({
          'Authorization': `Bearer ${token}`
        })
      });
      
      const responseData = await response.json();
      //console.log(responseData);
      //console.log(responseData.length);
      
      this.setState({
        loadingUser: false,
        user_extended: responseData,
      });
      
    } catch (error) {
      console.error(error);
    }
  }
  
  componentDidUpdate(){
    const { getTokenSilently, loading, user } = this.context;
    if(loading!==this.state.loading) {
      this.setState({loading:loading, user:user });
      this.getUser();
    }
    //if(this.state.loading===false) 
  }
  
  changeWorkArea(to) {
    this.setState({current:to});
    //console.log(to);
  }
  
  render(){
    return(
      this.state.authenticated ? (
        !this.state.loading && !this.state.loadingUser ? (
        
          <Container id="main-container" fluid>
            <Row>
              <Col id="sidebar" lg={3} md={3} xs={3}>
                <Sidebar current={this.state.current} go={this.changeWorkArea.bind(this)} />
              </Col>
              <Col id="workarea" lg={9} md={9} xs={9}>
                <WorkArea current={this.state.current} userInfo={this.state.user_extended} />
              </Col>
            </Row>
          </Container>
        ) : (
          <Spinner />
        )
      ) : (
        <Redirect to={{
          pathname: '/login'
        }} />
      )
    )
  }
  
}

export default Root;