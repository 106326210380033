import React from "react";
import "./spinner.style.css";


const Spinner = () => {
  //const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <div id="spinner-wrapper">
      <img src="/spinner.png" width={100} height={100} className="ld ld-spin" />
    </div>
  );
};

export default Spinner;