import React, { Component} from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import PrivateRoute from './components/PrivateRoute.js';
import logo from './logo.svg';
import Root from './components/pages/root';
import NavBar from "./components/navbar";
//import { useAuth0 } from "./react-auth0-wrapper";
import Header from './components/header';
import Top from './components/top';
import Private from './components/private';
import Login from './auth/login';
import Logout from './auth/logout';
import isAuthenticated from './auth/isauthenticated';
import ExternalApi from "./components/ExternalApi";
import './App.css';

function App() {
  
  //<Route path="/" component={Root} />
  //<Header />
  return (
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/" component={isAuthenticated() ? Root : Login} />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route component={Root} />
          </Switch>
        </div>
      </Router>
  );
}

export default App;
