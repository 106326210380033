import React, { Component} from "react";
import ReactDOM from 'react-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Auth0Context } from "../../react-auth0-wrapper";
import Spinner from '../spinner';
import "./workareas.style.css";

const prettyCategory = {
  cleanup: 'Curacao Cleanup',
  socialdistance: 'Social Distancing',
  businessopen: 'Unauthorized Business Open',
  curfew: 'Curfew Violation',
  pothole: 'Pothole',
  access: 'Accessibility issue',
  kumpra_konsiente: 'Kumpra Konsiente',
  accident: 'Accident',
  garbage: 'Garbage in the street',
  dumping: 'Illegal Dumping',
  dead_animal: 'Dead Animal',
  bulky: 'Bulky Waste',
  graffiti: 'Graffiti',
  vandalism: 'Vandalism',
  stray_animal: 'Stray animal',
  power_line_water: 'Broken Power Line / Water Main',
  food_safety: 'Food Safety',
  price_difference: 'Price difference',
  other: 'Other'
}

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResult: false, 
      apiMessage: this.props.userInfo,
      loadingRows: false,
      user_extended: this.props.userInfo,
    };
    
    this.getStuff = this.getStuff.bind(this);
    this.getProperDate = this.getProperDate.bind(this);
    //this.afterOpenModal = this.afterOpenModal.bind(this);
    //this.closeModal = this.closeModal.bind(this);
  }
  static contextType = Auth0Context;
  
  
  componentDidMount() {
    //console.log('from props',this.state.apiMessage);
    //this.getStuff();
  }
  
  // not used anymore because stuff is passed down through this.props
  async getStuff(){
    const { getTokenSilently } = this.context;

    try {
      const token = await getTokenSilently();
      //console.log(token);
      this.setState({loadingRows:true});
      const response = await fetch("https://api2.curacao311.com/api/private/get_userinfo", {
        headers: new Headers({
          'Authorization': `Bearer ${token}`
        })
      });
      
      const responseData = await response.json();
      //console.log(responseData);
      //console.log(responseData.length);
      
      this.setState({
        showResult: true,
        apiMessage:responseData,
        loadingRows: false,
      });
      
    } catch (error) {
      console.error(error);
    }
  }
  
  getProperDate(dstring){
    const d=new Date(dstring);
    var dYear = d.getFullYear();
    var dMonths = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var dMonth = dMonths[d.getMonth()];
    var dDay = (d.getDate()<10) ? '0'+d.getDate() : d.getDate();
    var dHour = (d.getHours()<10) ? '0'+d.getHours() : d.getHours();
    var dMin = (d.getMinutes()<10) ? '0'+d.getMinutes() : d.getMinutes();
    var theDate = dMonth + ' ' + dDay + ', ' + dYear + ' ' + dHour + ':' + dMin;
    return theDate;
  }
  
  render(){
    //this.getStuff();
    return(
      <div id="profile">
        {!this.state.loadingRows ? (
          <Container fluid>
            <Row>
              <Col sm={12} xs={12} md={8} id="profileLeft">
                <div className="plOuter">
                  <div className="plHeader">
                    <h2>Profile</h2>
                    <p>Your profile and other information in our system</p>
                  </div>
                  <div className="plInfo">
                    <Container>
                      <Row>
                        <Col sm={12} xs={12} md={6} className="plInfoLeft">
                          <p><strong>Organization:</strong> {this.state.apiMessage.full_user.app_metadata.Organization}</p>
                          <p><strong>Categories: </strong>
                          {Object.keys(this.state.apiMessage.full_user.app_metadata.Categories).map(key =>
                            <span key={key}> {prettyCategory[key]}, </span>
                          )}
                          </p>
                          <p><strong>Your 311 contact:</strong> <a href="mailto:luigi.scuotto@smartcaribbean.io">Luigi Scuotto</a></p>
                        </Col>
                        <Col sm={12} xs={12} md={6} className="plInfoRight">
                          <p><strong>Email:</strong> {this.state.apiMessage.full_user.email} <em>({this.state.apiMessage.full_user.email_verified ? '' : 'Not '}Verified)</em></p>
                          <p><strong>Last login:</strong> {this.getProperDate(this.state.apiMessage.full_user.last_login)}<br /> from {this.state.apiMessage.full_user.last_ip} </p>
                          <p><strong>Your role(s):</strong> 
                          {this.state.apiMessage.roles.map((item,key) =>
                            <span key={key}> {item.name}, </span>
                          )}
                          </p>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </Col>
              <Col sm={12} xs={12} md={4} id="profileRight">
                <div className="prOuter">
                  <div className="prImg">
                    <img src={this.state.apiMessage.full_user.picture} width={150} height={150} alt="Profile picture" /> 
                  </div>
                  <div className="prInfo">
                    <h2>{this.state.apiMessage.full_user.name}</h2>
                    <h4>{this.state.apiMessage.full_user.app_metadata.Organization}</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vitae orci id ipsum tincidunt 
                      feugiat scelerisque vitae erat. Quisque erat metus, molestie vel suscipit in, venenatis 
                      at ipsum.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <Spinner />
        )}
      </div>
    )
  }
  
}

export default Profile;