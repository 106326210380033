import React, { Component, useState, setState} from "react";
import ReactDOM from 'react-dom';
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class DoToasts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      title: this.props.tvalues.title,
      body: this.props.tvalues.body,
    }
  }
//export function DoToasts() {
//  const [show, setShow] = useState(false);
//
  render(){
    return (
      (this.state.show && 
        <div className={"toast-"+this.state.title}>
            <Toast onClose={() => this.setState({show:false})} show={this.state.show} delay={3000} autohide>
              <Toast.Header>
                <img
                  height={24}
                  width={24}
                  src="/Message-White.png"
                  className="toast-himg mr-2"
                  alt="Message"
                />
                <strong className="mr-auto">{this.state.title}</strong>
                <small>Just now</small>
              </Toast.Header>
              <Toast.Body>{this.state.body}</Toast.Body>
            </Toast>
        </div>
      )
    );
  }
}

export default DoToasts;
