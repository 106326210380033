import React from 'react';
import { Redirect } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Lock from './lock';
import isAuthenticated from './isauthenticated';

const Login = (props) => (
  isAuthenticated() ? (
    <Redirect to={{
      pathname: '/private',
      state: { from: props.location }
    }} />
  ) : (
    <Modal show={true} centered size='sm' dialogClassName="login-modal">
      <Lock location={props.location} />
    </Modal>
  )
)

export default Login;