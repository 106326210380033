import React, { Component} from "react";
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router';
import ListGroup from 'react-bootstrap/ListGroup';
import Media from 'react-bootstrap/Media';
import "./sidebar.style.css";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: this.props.current,
    };
    
    //this.openModal = this.openModal.bind(this);
    //this.afterOpenModal = this.afterOpenModal.bind(this);
    //this.closeModal = this.closeModal.bind(this);
  }
  
  alertClicked() {
    window.alert('clicked');
  }
  //<ListGroup.Item action onClick={() => this.props.history.push('/reports')}>
  render(){
    return(
      <div>
        <img src="/logo-cur311-white.png" id="logo" />
        <div className="sidemenu">
          <ListGroup>
            <ListGroup.Item action onClick={() => this.props.go('/')}>
              <Media>
                <img
                  height={25}
                  className="side-icon align-self-start mr-3"
                  src="/Shutter-White.png"
                  alt="Dashboard"
                />
                <Media.Body>
                  <h5>Dashboard</h5>
                </Media.Body>
              </Media>
            </ListGroup.Item>
            
            <ListGroup.Item action onClick={() => this.props.go('/reports')}>
              <Media>
                <img
                  height={25}
                  className="side-icon align-self-start mr-3 ml-1"
                  src="/File.png"
                  alt="Reports"
                />
                <Media.Body>
                  <h5>Reports</h5>
                </Media.Body>
              </Media>
            </ListGroup.Item>
            
            <ListGroup.Item action onClick={() => this.props.go('/map')}>
              <Media>
                <img
                  height={25}
                  className="side-icon align-self-start mr-3 ml-1"
                  src="/Marker-White.png"
                  alt="Map"
                />
                <Media.Body>
                  <h5>Map</h5>
                </Media.Body>
              </Media>
            </ListGroup.Item>
            
            <ListGroup.Item action onClick={() => this.props.go('/profile')}>
              <Media>
                <img
                  height={25}
                  className="side-icon align-self-start mr-3"
                  src="/User-White.png"
                  alt="Reports"
                />
                <Media.Body>
                  <h5>Profile</h5>
                </Media.Body>
              </Media>
            </ListGroup.Item>
            
          </ListGroup>
        </div>
      </div>
    )
  }
  
}

export default withRouter(Sidebar);