import React, { Component} from "react";
import ReactDOM from 'react-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Auth0Context } from "../../react-auth0-wrapper";
import {saveAs} from 'file-saver';
import Graphs from './graphs';
import Spinner from '../spinner';

import "./workareas.style.css";

const prettyCategory = {
  cleanup: 'Curacao Cleanup',
  socialdistance: 'Social Distancing',
  businessopen: 'Unauthorized Business Open',
  curfew: 'Curfew Violation',
  pothole: 'Pothole',
  access: 'Accessibility issue',
  kumpra_konsiente: 'Kumpra Konsiente',
  accident: 'Accident',
  garbage: 'Garbage in the street',
  dumping: 'Illegal Dumping',
  dead_animal: 'Dead Animal',
  bulky: 'Bulky Waste',
  graffiti: 'Graffiti',
  vandalism: 'Vandalism',
  stray_animal: 'Stray animal',
  power_line_water: 'Broken Power Line / Water Main',
  food_safety: 'Food Safety',
  price_difference: 'Price difference',
  other: 'Other'
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResult: false, 
      apiMessage: '',
      fullList: '',
      loadingRows: true,
      user_extended: this.props.userInfo,
      downloading: false,
      categories: [],
      catsList: [],
    };
    
    this.getLastReportTime = this.getLastReportTime.bind(this);
    this.getDownloads = this.getDownloads.bind(this);
    this.getCategoriesReports = this.getCategoriesReports.bind(this);
    this.getStuff = this.getStuff.bind(this);
    //this.afterOpenModal = this.afterOpenModal.bind(this);
    //this.closeModal = this.closeModal.bind(this);
  }
  static contextType = Auth0Context;
  
  componentDidMount() {
    this.getStuff();
    //console.log(this.state.user_extended);
  }
  
  async getStuff(){
    const { getTokenSilently } = this.context;
    try {
      const token = await getTokenSilently();
      //console.log(token);
      this.setState({loadingRows:true});
      const response = await fetch("https://api2.curacao311.com/api/private/get_reports", {
        headers: new Headers({
          'Authorization': `Bearer ${token}`
        })
      });
      //console.log(token);
      
      const responseData = await response.json();
      //console.log(responseData);
      //console.log(responseData.length);
      
      this.setState({
        showResult: true,
        apiMessage:responseData,
        fullList: responseData,
        listLength: responseData.length,
        loadingRows: false,
      }, this.getCategoriesReports);
      
    } catch (error) {
      console.error(error);
    }
  }
  
  async getDownloads(){
    const { getTokenSilently } = this.context;
    try {
      const token = await getTokenSilently();
      //console.log(token);
      this.setState({downloading:true});
      await fetch("https://api2.curacao311.com/api/private/download-category", {
        headers: new Headers({
          'Authorization': `Bearer ${token}`,
          'categories': JSON.stringify(this.state.user_extended.full_user.app_metadata.Categories)
        }),
        responseType: 'blob'
      }).then(response => response.blob())
        .then(blob => saveAs(blob, 'reports-311.csv'));
      
    } catch (error) {
      console.error(error);
    }
  }
  
  getCategoriesReports() {
    if(this.state.apiMessage!=='') {
      const cats = [];
      const catsList = [];
      Object.keys(this.state.user_extended.full_user.app_metadata.Categories).map(key =>
        cats.push(key)
      )
      for(var i=0;i<this.state.fullList.length;i++){
        if(cats.includes(this.state.fullList[i].reportType)) catsList.push(this.state.fullList[i]);
      }
      this.setState({
        categories: cats,
        catsList: catsList,
      });
    }
  }
  
  getLastReportTime() {
    if(this.state.apiMessage!=='') {
      const lastReport = this.state.apiMessage[0].submitted;
      const d = new Date();
      const now = d.getTime();
      const diff = now-lastReport;
      const days = Math.floor(diff / (1000*60*60*24));
      const hours = Math.floor(diff%(1000*60*60*24) / (1000*60*60));
      return <span>&nbsp;{days} days, {hours} hours&nbsp;</span>;
    }
    else return null;
  }

  render(){
    //this.getStuff();
    return(
      <div id="dashboard">
        {!this.state.loadingRows ? (
          <Container fluid>
            
            
            <Row>
              <Col sm={12} xs={12} md={8} id="dashLeft">
                <Container>
                  <Row>
                    <Col sm={12} xs={12} md={12} lg={12} xl={8} id="dleft1" className="plus-graph plus-graph-green">
                      <div className="plus-graph-outer">
                        <div className="plus-graph-graph">
                          <Graphs which="reportsWeekly" allData={this.state.apiMessage} />
                        </div>
                        <div className="plus-graph-text">
                          <h4>Weekly Reports</h4>
                          <p>Reports over the past 8 weeks</p>
                        </div>
                        <div className="plus-graph-footer">
                          <img src="/Clock-Blue.png" className="mr-3" height={16} width={16} role="presentation" alt="clock" />
                          Last report {this.getLastReportTime()} ago
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} xs={12} md={12} lg={12} xl={4} id="dleft2" className="">
                      <div className="small-dash small-dash-yellow">
                        <div className="small-dash-imgtext">
                          <div className="small-dash-img">
                            <img src="/Download-White.png" width={56} height={56} role="presentation" alt="download" />
                          </div>
                          <p>Your reports</p>
                          <h4>{this.state.catsList.length>0 ? this.state.catsList.length : ''}</h4>
                        </div>
                        <div className="small-dash-footer" onClick={()=>this.getDownloads()}>
                          <img src="/Link-Blue.png" className="mr-3" height={16} width={16} role="presentation" alt="link" />
                          Download your reports
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
                
                
              </Col>
              <Col sm={12} xs={12} md={4} id="dashRight profileRight">
                <div className="prOuter">
                  <div className="prImg">
                    <img src={this.state.user_extended.full_user.picture} width={150} height={150} alt="Profile picture" /> 
                  </div>
                  <div className="prInfo">
                    <h2>{this.state.user_extended.full_user.name}</h2>
                    <h4>{this.state.user_extended.full_user.app_metadata.Organization}</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vitae orci id ipsum tincidunt 
                      feugiat scelerisque vitae erat. Quisque erat metus, molestie vel suscipit in, venenatis 
                      at ipsum.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <Spinner />
        )}
      </div>
    )
  }
  
}

export default Dashboard;