import React, { Component} from "react";
import ReactDOM from 'react-dom';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import DoToasts from './toasts.function';
import MyMarker from './markers';
import { Auth0Context } from "../../react-auth0-wrapper";
import Spinner from '../spinner';

import "./workareas.style.css";

const prettyCategory = {
  cleanup: 'Curacao Cleanup',
  socialdistance: 'Social Distancing',
  businessopen: 'Unauthorized Business Open',
  curfew: 'Curfew Violation',
  pothole: 'Pothole',
  access: 'Accessibility issue',
  kumpra_konsiente: 'Kumpra Konsiente',
  accident: 'Accident',
  garbage: 'Garbage in the street',
  dumping: 'Illegal Dumping',
  dead_animal: 'Dead Animal',
  bulky: 'Bulky Waste',
  graffiti: 'Graffiti',
  vandalism: 'Vandalism',
  stray_animal: 'Stray animal',
  power_line_water: 'Broken Power Line / Water Main',
  food_safety: 'Food Safety',
  price_difference: 'Price difference',
  other: 'Other'
}

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResult: false, 
      apiMessage: '',
      fullList: '',
      page: 0,
      pageLength: 24,
      listLength: 0,
      currentSort: 'reportID',
      currentSortBy: 'desc',
      filters: {
        reportType: [],
        theirName: [],
      },
      waitingToSolve: [],
      toasts: [],
      loadingRows: false,
      user_extended: this.props.userInfo,
      showMap: this.props.showMap,
      mapReport: {
        lat: 12.120787,
        lon: -68.924019,
        zoom: 13
      },
      mapReportActive: null,
    };
    
    this.getStuff = this.getStuff.bind(this);
    //this.afterOpenModal = this.afterOpenModal.bind(this);
    //this.closeModal = this.closeModal.bind(this);
  }
  static contextType = Auth0Context;
  
  componentDidMount() {
    this.getStuff();
  }
  
  async getStuff(){
    const { getTokenSilently } = this.context;
    try {
      const token = await getTokenSilently();
      //console.log(token);
      this.setState({loadingRows:true});
      const response = await fetch("https://api2.curacao311.com/api/private/get_reports", {
        headers: new Headers({
          'Authorization': `Bearer ${token}`,
          'categories': JSON.stringify(this.state.user_extended.full_user.app_metadata.Categories)
        })
      });
      //console.log(token);
      
      const responseData = await response.json();
      //console.log(responseData);
      //console.log(responseData.length);
      
      this.setState({
        showResult: true,
        apiMessage:responseData,
        fullList: responseData,
        listLength: responseData.length,
        loadingRows: false,
      });
      
      if(this.state.showMap){
        this.showAllFilters('reportType');
      }
      
    } catch (error) {
      console.error(error);
    }
  }
  
  async sendStuff(what,values) {
    const { getTokenSilently } = this.context;
    let tvalues = {};
    let index, temp;
    try {
      const token = await getTokenSilently();
      //console.log(token);
      if(what=='solved') {
        this.setState({waitingToSolve:this.state.waitingToSolve.concat(values.reportID)});
        
        const url = new URL("https://api2.curacao311.com/api/private/set_solved");
        Object.keys(values).forEach(key => url.searchParams.append(key, values[key]));
        const response = await fetch(url, {
          method: 'GET',
          headers: new Headers({
            'Authorization': `Bearer ${token}`
          })
        });
        
        temp = this.state.waitingToSolve;
        index = temp.indexOf(values.reportID);
        temp.splice(index,1);
        this.setState({waitingToSolve:temp});
        
        const responseData = await response.json();
        
        tvalues = {
          title: responseData.status,
          body: responseData.message,
        }
        this.setState({toasts:this.state.toasts.concat(<DoToasts key={this.state.toasts.length} tvalues={tvalues} />)});
        
        // update solved field in state apimessage+fullist
        if(responseData.status!=='error') {
          let apiMessageTemp = [];
          let fullListTemp = [];
          this.state.apiMessage.map((item, key) =>
            {if(item.reportID==values.reportID) {
                item.solved = values.setTo;
                apiMessageTemp.push(item);
              } else {
                apiMessageTemp.push(item);
            }}
          )
          this.state.fullList.map((item, key) =>
            {if(item.reportID==values.reportID) {
                item.solved = values.setTo;
                fullListTemp.push(item);
              } else {
                fullListTemp.push(item);
            }}
          )
          this.setState({
            apiMessage: apiMessageTemp,
            fullList: fullListTemp,
          })
        };
      }
      
       
    } catch (error) {
      console.error(error);
    }
  }
  
  sendSolved(item) {
    const setTo = item.solved=='yes' ? 'no' : 'yes';
    const reportID = item.reportID;
    const insert = item.solved==null ? 'yes' : 'no';
    
    const values = {
      setTo: setTo,
      reportID: reportID,
      insert: insert,
      organization: this.state.user_extended.full_user.app_metadata.Organization,
      user: this.state.user_extended.full_user.email
    };
    this.sendStuff('solved',values);
  }
  
  getPrettyDate(timestamp){
    var d = new Date(parseInt(timestamp,10));
    var dYear = d.getFullYear();
    var dMonths = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var dMonth = dMonths[d.getMonth()];
    var dDay = (d.getDate()<10) ? '0'+d.getDate() : d.getDate();
    var dHour = (d.getHours()<10) ? '0'+d.getHours() : d.getHours();
    var dMin = (d.getMinutes()<10) ? '0'+d.getMinutes() : d.getMinutes();
    var submitted = dMonth + ' ' + dDay + ', ' + dYear + ' ' + dHour + ':' + dMin;
    return submitted;
  }
  
  sortByWhich(which) {
    const newSortBy = this.state.currentSort!=which || (this.state.currentSort==which && this.state.currentSortBy=='desc') ? 'asc' : 'desc';
    const myData =  newSortBy=='asc' ? (
      [].concat(this.state.apiMessage)
      .sort(function(a,b){
        if(a[which] < b[which]) { return -1; }
        if(a[which] > b[which]) { return 1; }
        return 0;
      })
    ) : (
      [].concat(this.state.apiMessage)
      .sort(function(a,b){
        if(a[which] > b[which]) { return -1; }
        if(a[which] < b[which]) { return 1; }
        return 0;
      })
    )
    
    this.setState({
      apiMessage: myData,
      currentSort: which,
      currentSortBy: newSortBy,
    });
  }
  //ar = array('rt')
  toggleFilter(type,value){
    let tempFilters;
    if(!this.state.filters[type].includes(value)) {
      tempFilters = this.state.filters;
      tempFilters[type].push(value);
      this.setState(
        {filters: tempFilters}
      );
    }
    else {
      tempFilters = this.state.filters;
      const index = tempFilters[type].indexOf(value);
      tempFilters[type].splice(index,1);
      this.setState({
        filters: tempFilters,
      });
    }
    let myData2=[];
    let doubleCheck=[];
    let inThere;
    const myData = [].concat(this.state.fullList).map((item, key) =>
      Object.keys(this.state.filters).map((key2) => 
        //myData2.push(this.state.filters[key2].includes(item.reportType) ? item : null)
        
        this.state.filters[key2].includes(item[key2]) && !doubleCheck.includes(item.reportID) ? myData2.push(item) && doubleCheck.push(item.reportID) : null
        
        //reportType ["garbage"] garbage
        //console.log(key2, this.state.filters[key2],item[key2])
      )
    );
    if(myData2.length==0) myData2 = this.state.fullList;
    this.setState({
      apiMessage: myData2,
      listLength: myData2.length,
    });
  }
  
  getUniqueItems(which) {
    const uniqItems = [];
    const uniqItemsPretty = []
    let ret = [];
    if(this.state.fullList != ''){
      this.state.fullList.map((item, key) =>
        !uniqItems.includes(item[which]) ? uniqItems.push(item[which]) : null
      );
      for(let i=0; i<uniqItems.length; i++) {
        ret.push(
          <div key={'key-'+i} id={which+'-filter-'+uniqItems[i]} className={this.state.filters[which].includes(uniqItems[i]) ? 'filterItem active' : 'filterItem'} onClick={()=>this.toggleFilter(which,uniqItems[i])}>
            <span className="checkbox"></span>
            {which=='reportType' ? prettyCategory[uniqItems[i].replace(/-/g,'_')] : uniqItems[i]=='null' ? 'None' : uniqItems[i]}
          </div>
        );
      };
      //console.log('hoi',which, ret, uniqItems, this.state.apiMessage);
      return ret;
    }
  }
  
  showAllFilters(which){
    const uniqItems = [];
    const uniqItemsPretty = []
    if(this.state.fullList != ''){
      this.state.fullList.map((item, key) =>
        !uniqItems.includes(item[which]) ? uniqItems.push(item[which]) : null
      );
      for(let i=0; i<uniqItems.length; i++) {
        this.toggleFilter(which,uniqItems[i]);
      }
    }
  }
  
  toggleMap(which,geoLocation){
    let lat; 
    let lon;
    if(geoLocation!==null){
      const latLon = geoLocation.split(',');
      lat = latLon[0];
      lon = latLon[1];
    }
    
    this.setState({
      showMap: which!==null ? true : false,
      mapReportActive: which,
      mapReport: {
        lat: geoLocation!==null ? lat : 12.120787,
        lon: geoLocation!==null ? lon : -68.924019,
        zoom: 13
      }
    });
  }

  handleRowClick(item) {
    console.log(item);
  }
  
  //<td><a href={"https://www.curacao311.com/report/"+item.reportID} target="_blank">{item.reportID}</a></td>
  
  render(){
    //this.getStuff();
    return(
      <div id="reports">
        <div id="toasts-all">
          {this.state.toasts}
        </div>
        <div className="prettyTable">
          <div className="prettyTableHead">
            <h4>Reports</h4>
            <p>Reports submitted to Curaçao 311</p>
          </div>
          {!this.state.loadingRows ? (
            <div className="prettyTableTable">
              <div>
                {this.state.filters.reportType.length != 0 && this.state.filters.reportType.length != 0 ? (
                  <div className="filterList"><span className="filterListTitle">Filter: </span> 
                  {this.state.filters.reportType.map((item,key) =>
                    <div key={key} className="filterListFilter" onClick={()=>this.toggleFilter('reportType',item)}>{prettyCategory[item.replace(/-/g,'_')]}</div>
                  )}
                  {this.state.filters.theirName.map((item,key) =>
                    <div key={key} className="filterListFilter" onClick={()=>this.toggleFilter('theirName',item)}>{item=='null' ? 'None' : item}</div>
                  )}
                  </div>
                ) : (null)}
                
                {this.state.showMap ? (
                  <div>
                    {this.state.mapReportActive!==null && 
                      <div className="closeMap" onClick={()=>this.toggleMap(null,null)}>Back to reports</div>}
                    <Map ref='map' center={[this.state.mapReport.lat,this.state.mapReport.lon]} zoom={this.state.mapReport.zoom}>
                      <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <MarkerClusterGroup
                        showCoverageOnHover={false}
                        maxClusterRadius={30}
                      >
                        {this.state.apiMessage!='' ? (
                            this.state.apiMessage.map((item, key) => {
                              const latLon = item.geoLocation.split(',');
                              const reportCategory = item.reportType.replace(/_/g,'-');
                              if(this.state.mapReportActive===null || this.state.mapReportActive===item.reportID){
                                return (
                                  <MyMarker map={this.refs.map} key={item.reportID} markerId={item.reportID} position={[latLon[0],latLon[1]]} category={reportCategory} fullItem={item} />
                                )
                              }
                            })
                          ) : null
                        }
                      </MarkerClusterGroup>
                    </Map>
                  </div>
                ) : (
                
                  <Table hover responsive>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th className="prettyTableHeaderCell clickable">
                          <div onClick={()=>this.sortByWhich('reportID')}>
                            <span className="caret-empty"></span>
                            ID 
                            <span className={this.state.currentSort=='reportID' && this.state.currentSortBy=='desc' ? 'caret-desc' : this.state.currentSort=='reportID' ? 'caret-asc' : 'caret'}></span>
                          </div>
                        </th>
                        <th className="prettyTableHeaderCell clickable">
                          <OverlayTrigger trigger="click" key="optionsReportID" placement="right" rootClose={true} overlay={
                            <Popover id="popover-optionsReportID" className="filterPopover">
                              <Popover.Title as="h3">
                                Filter by type:
                              </Popover.Title>
                              <Popover.Content className="filterItems">
                              {this.state.apiMessage != '' ? this.getUniqueItems('reportType') : ''}
                              </Popover.Content>
                            </Popover>
                          }>
                            <div className="th-options">
                              <img src="/Dots-Vert-Blue.png" className="dots"/>
                            </div>
                          </OverlayTrigger>
                          <div onClick={()=>this.sortByWhich('reportType')}>
                            <span className="caret-empty"></span> 
                            Type 
                            <span className={this.state.currentSort=='reportType' && this.state.currentSortBy=='desc' ? 'caret-desc' : this.state.currentSort=='reportType' ? 'caret-asc' : 'caret'}></span>
                          </div>
                        </th>
                        <th className="prettyTableHeaderCell clickable" onClick={()=>this.sortByWhich('imgName')}><span className="caret-empty"></span> Image <span className={this.state.currentSort=='imgName' && this.state.currentSortBy=='desc' ? 'caret-desc' : this.state.currentSort=='imgName' ? 'caret-asc' : 'caret'}></span></th>
                        <th className="prettyTableHeaderCell clickable">
                          <OverlayTrigger trigger="click" key="optionsTheirName" placement="right" rootClose={true} overlay={
                            <Popover id="popover-optionsTheirName" className="filterPopover">
                              <Popover.Title as="h3">Filter by name:</Popover.Title>
                              <Popover.Content className="filterItems">
                              {this.state.apiMessage != '' ? this.getUniqueItems('theirName') : ''}
                              </Popover.Content>
                            </Popover>
                          }>
                            <div className="th-options">
                              <img src="/Dots-Vert-Blue.png" className="dots"/>
                            </div>
                          </OverlayTrigger>
                          <div onClick={()=>this.sortByWhich('theirName')}>
                            <span className="caret-empty"></span> 
                            Name 
                            <span className={this.state.currentSort=='theirName' && this.state.currentSortBy=='desc' ? 'caret-desc' : this.state.currentSort=='theirName' ? 'caret-asc' : 'caret'}></span>
                          </div>
                        </th>
                        <th className="prettyTableHeaderCell">Comment</th>
                        <th className="prettyTableHeaderCell clickable" onClick={()=>this.sortByWhich('submitted')}><span className="caret-empty"></span> Submitted <span className={this.state.currentSort=='submitted' && this.state.currentSortBy=='desc' ? 'caret-desc' : this.state.currentSort=='submitted' ? 'caret-asc' : 'caret'}></span></th>
                        <th>Solved</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.apiMessage!='' ? (
                        this.state.apiMessage.map((item, key) =>
                          key >= this.state.page * this.state.pageLength && key < (this.state.page+1) * this.state.pageLength ? (
                            <tr key={key}>
                              <td className="td-info">
                                {item.agreed==='yes' && (item.theirEmail!='null' || item.theirPhone!='null') ? (
                                  <OverlayTrigger trigger="click" key={key+"-i"} placement="right" rootClose={true} overlay={
                                    <Popover id={`popover-${key}-i`} className="filterPopover">
                                      <Popover.Title as="h3">Contact details</Popover.Title>
                                      <Popover.Content>
                                        {item.theirName!=='' && item.theirName!=='null' ? <div><b>{item.theirName}</b></div> : ''}
                                        {item.theirEmail!=='' && item.theirEmail!=='null' ? <div><a href={`mailto:${item.theirEmail}`}>{item.theirEmail}</a></div> : ''}
                                        {item.theirPhone!=='' && item.theirPhone!=='null' ? <div><a href={`tel:${item.theirPhone}`}>{item.theirPhone}</a></div> : ''}
                                      </Popover.Content>
                                    </Popover>
                                  }>
                                    <img src="/Info-Blue.png" height={20} width={20} /> 
                                  </OverlayTrigger>
                                ) : ('')}
                              </td>
                              <td onClick={()=>this.toggleMap(item.reportID,item.geoLocation)} className="td-id">{item.reportID}</td>
                              <td>{prettyCategory[item.reportType.replace(/-/g,'_')]}</td>
                              <td>
                                <OverlayTrigger trigger={["hover","focus"]} key={key} placement="right" overlay={
                                  <Popover id={`popover-${key}`} className="reportPopover">
                                    <Popover.Title as="h3">Report</Popover.Title>
                                    <Popover.Content>
                                      <img src={"https://api.curacao311.com/uploads/"+item.imgName+"-ss.jpg"} />
                                    </Popover.Content>
                                  </Popover>
                                }>
                                {item.imgName!='' ? (
                                  <img src="/Camera.png" height={20} width={20} /> 
                                ) : (<div></div>)}
                                </OverlayTrigger>
                              </td>
                              <td>{item.theirName!='null' ? item.theirName : ''}</td>
                              <td className="tableComments">{item.comments!='null' ? item.comments : ''} </td>
                              <td>{this.getPrettyDate(item.submitted)}</td>
                              <td className={this.state.waitingToSolve.includes(item.reportID) ? 'waiting' : 'wecool'}>
                                <div className="spinner">
                                  <div className="rect1"></div>
                                  <div className="rect2"></div>
                                  <div className="rect3"></div>
                                  <div className="rect4"></div>
                                  <div className="rect5"></div>
                                </div>
                                <button className={item.solved=='yes' ? "toggle toggle-on" : "toggle toggle-off"} onClick={()=>this.sendSolved(item)} ></button>
                              </td>
                            </tr>
                          ) : (null)
                        )
                      ) : (null)}
                    </tbody>
                  </Table>
                )}
                  
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
        
        {!this.state.showMap && 
          <Pagination>
            <Pagination.First onClick={() => this.setState({page:0})} />
            {this.state.page>0 ? (
              <Pagination.Prev onClick={() => this.setState({page:this.state.page-1})} />
            ) : ('')}
            {this.state.apiMessage!='' ? (
                
                (this.state.apiMessage.map((item, key) =>
                  (key+1)%this.state.pageLength==0 
                    ? ( 
                      <Pagination.Item key={key} active={this.state.page==((key+1)/this.state.pageLength)-1 ? true : false} onClick={() => this.setState({page:((key+1)/this.state.pageLength)-1})}>{(key+1)/this.state.pageLength}</Pagination.Item> ) 
                    : ('')
                ))
                
              ) : ('')
            }
            {
              this.state.listLength % this.state.pageLength > 0 ? ( <Pagination.Item active={this.state.page==Math.floor(this.state.listLength / this.state.pageLength) ? true : false} onClick={() => this.setState({page:Math.floor(this.state.listLength / this.state.pageLength)})}>{Math.ceil(Math.ceil(this.state.listLength / this.state.pageLength))}</Pagination.Item> ) : ('')
            }
            {this.state.page < Math.floor(this.state.listLength / this.state.pageLength) ? (
              <Pagination.Next onClick={() => this.setState({page:this.state.page+1})} />
            ) : ('')}
            <Pagination.Last />
          </Pagination>
        }
      </div>
    )
  }
  
}

export default Reports;