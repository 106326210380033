import React, { Component} from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Link, Switch, withRouter } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-wrapper";
import Reports from '../workareas/reports';
import Dashboard from '../workareas/dashboard';
import Profile from '../workareas/profile';
import NavBar from '../navbar';
import "./workarea.style.css";

class WorkArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentroute: this.props.location.pathname,
      current: this.props.current,
    };
    
    //this.openModal = this.openModal.bind(this);
    //this.afterOpenModal = this.afterOpenModal.bind(this);
    //this.closeModal = this.closeModal.bind(this);
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    if(this.props.current !== prevProps.current) {
      this.setState({current:this.props.current});
    }
  }

  onRouteChanged() {
    this.setState({currentroute:this.props.location.pathname,});
  }

  //<Route path="/reports" component={Reports} />
  render(){
    return(
      <>
        <NavBar />
        {this.state.current==='/' ? <Dashboard userInfo={this.props.userInfo} /> : '' }
        {this.state.current==='/reports' ? <Reports userInfo={this.props.userInfo} showMap={false} /> : '' }
        {this.state.current==='/map' ? <Reports userInfo={this.props.userInfo} showMap={true} /> : '' }
        {this.state.current==='/profile' ? <Profile userInfo={this.props.userInfo} /> : '' }
      </>
    )
  }
  
}

export default withRouter(WorkArea);